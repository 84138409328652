<template>
  <a-modal
    :title="modalTitle"
    class="page-row-item"
    width="60%"
    v-model="visible"
    @cancel="handleClose"
    :footer="null">
    <div class="page-content">
      <p-table
        ref="table"
        :columns="tableColumns"
        :fileName="modalTitle"
        download
        :sourceData="getDataApi"
        :scroll="{ y: 400 }"
        row-key="id"
      >
        <template slot="headerLeft">
          <span></span>
        </template>
      </p-table>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'UserList',
  data () {
    return {
      visible: false,
      modalTitle: '',
      tableData: [],
      tableColumns: [
        {
          title: '序号',
          dataIndex: 'key',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '工人姓名',
          download: true,
          dataIndex: 'realName'
        },
        {
          title: '身份证号',
          download: true,
          dataIndex: 'idCard',
          customRender: (text) => {
            if (text) {
              return text.replace(/^(\d{6})\d{8}(.{4}$)/g, `$1${Array(9).join('*')}$2`)
            }
            return text
          },
          width: 160
        },
        {
          title: '项目名称',
          download: true,
          dataIndex: 'projectName'
        },
        {
          title: '结算周期',
          download: true,
          dataIndex: 'date'
        },
        {
          title: '时间',
          download: true,
          dataIndex: 'createTime',
          customRender: (text, row) => row.createTime || row.updateTime,
          width: 180
        },
        {
          title: '金额',
          download: true,
          dataIndex: 'money',
          customRender: (t) => Math.round(t.replace('-', '') * 100) / 100,
          width: 100
        }
      ]
    }
  },
  computed: {},
  methods: {
    showModal ({ accountDetails = [], name = '' }) {
      this.visible = true
      this.tableData = [...accountDetails]
      this.modalTitle = name
      this.$nextTick(() => {
        this.$refs.table.initPage()
      })
    },
    handleClose () {
      this.tableData = []
      this.visible = false
    },
    getDataApi ({ pageIndex, pageSize }) {
      const s = (pageIndex - 1) * pageSize
      const e = pageIndex * pageSize
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            data: this.tableData.slice(s, e),
            total: this.tableData.length,
            pageIndex: pageIndex,
            pageSize: pageSize
          })
        }, 700)
      })
    }
  }
}
</script>

<style lang="less">
.page-row-item {
  .page-content {
    padding: 0 0 40px 0;
  }
}
</style>
