<template>
  <div class="recharge-detail-wrapper">
    <div class="com-header">收支明细</div>
    <div class="com-body">
      <div class="table-search">
        <div class="search-item">
          <span class="search-item-label">业务流水号：</span>
          <a-input v-model="form.number" placeholder="请输入业务流水号" @change="handleDebounceSearch" />
        </div>
        <div class="search-item">
          <span class="search-item-label">收支类型</span>
          <a-select
            v-model="form.type"
            style="width: 202px"
            allowClear
            placeholder="请选择收支类型"
            @change="$refs.table.initPage()"
          >
            <a-select-option v-for="item in typeOption" :value="item.value" :key="item.vlaue">{{
              item.label
            }}</a-select-option>
          </a-select>
        </div>
        <div class="search-item">
          <span class="search-item-label">更新时间：</span>
          <a-range-picker
            v-model="form.updateTime"
            value-format="YYYY-MM-DD HH:mm:ss"
            @change="$refs.table.initPage()"
          />
        </div>
        <a-button type="primary" class="export-btn" @click="$refs.table.handleFileDown()">导出表格</a-button>
      </div>
      <div class="search-box">
        <p-table
          row-key="id"
          :columns="tableColumn"
          :source-data="getTableData"
          ref="table"
          fileName="收支明细"
          :scroll="{ y: 550 }"
        >
          <template slot="actions" slot-scope="text, record">
            <a-button type="link" v-if="record.type == 2" @click="$refs.salaryDetails.showModal(record)">详情</a-button>
          </template>
        </p-table>
      </div>
      <salaryDetails ref="salaryDetails" />
    </div>
  </div>
</template>

<script>
import { queryBalanceDetails } from '@/api/balance'
import _ from 'lodash'
import salaryDetails from './salary-details.vue'

function NumRound (num) {
  return Math.round(num * 100) / 100
}

export default {
  name: 'RechargeDetail',
  components: { salaryDetails },
  data () {
    return {
      form: {},
      tableColumn: [
        {
          title: '序号',
          dataIndex: 'key',
          customRender: (text, row, index) => index + 1,
          width: 50
        },
        {
          title: '业务流水号',
          dataIndex: 'number',
          width: 280,
          download: true
        },
        {
          title: '收支类型',
          dataIndex: 'type',
          customRender: (text) => {
            const data = this.typeOption.find((item) => item.value == text)
            return data ? data.label : ''
          },
          download: true,
          width: 100
        },
        {
          title: '收支名称',
          dataIndex: 'name',
          download: true
        },
        // {
        //   title: '项目名称',
        //   download: true,
        //   dataIndex: 'projectName'
        // },
        // {
        //   title: '工人姓名',
        //   download: true,
        //   dataIndex: 'realName'
        // },
        // {
        //   title: '身份证号',
        //   download: true,
        //   dataIndex: 'idCard',
        //   customRender: (text) => {
        //     if (text) {
        //         return text.replace(/^(\d{6})\d{8}(.{4}$)/g, `$1${Array(9).join('*')}$2`)
        //     }
        //     return text
        //   },
        //   width: 160
        // },
        // {
        //   title: '结算周期',
        //   download: true,
        //   dataIndex: 'date'
        // },
        {
          title: '时间',
          download: true,
          dataIndex: 'createTime',
          customRender: (text, row) => row.createTime || row.updateTime,
          width: 180
        },
        {
          title: '金额',
          download: true,
          dataIndex: 'money',
          formatDownLoad: (text, row) => (row.type == 2 ? `- ${text}` : `+ ${text}`),
          customRender: (text, row) =>
            row.type == 2 ? (
              <span style="color:rgba(226, 31, 31, 1)">- {NumRound(text.replace('-', '')) }</span>
            ) : (
              <span style="color:rgba(0, 138, 55, 1)">+ {NumRound(text)}</span>
            ),
          width: 100
        },
        {
          title: '余额',
          download: true,
          dataIndex: 'balance',
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'actions',
          width: 100,
          scopedSlots: { customRender: 'actions' }
        }
      ],
      timer: null,
      typeOption: [
        { label: '预存薪资', value: '1' },
        { label: '工资发放', value: '2' },
        { label: '工资退款', value: '3' }
      ]
    }
  },
  methods: {
    getTableData (params) {
      const { updateTime = [], type, number } = this.form
      return queryBalanceDetails({
        ...params,
        updateTime: updateTime.join(','),
        number,
        type,
        enterpriseId: this.$store.getters.userInfo?.enterpriseInfoDto?.enterpriseId,
        status: 1
      })
    },
    handleDebounceSearch () {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$refs.table.initPage()
      }, 300)
    }
  }
}
</script>

<style lang="less">
.recharge-detail-wrapper {
  .com-header {
    height: 53px;
    padding: 0 24px;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    line-height: 53px;
    border-bottom: 1px solid #b1b1b1;
  }
  .com-body {
    padding: 24px;
  }
  .table-search {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    position: relative;
    .search-item {
      display: flex;
      align-items: center;
      margin-right: 40px;
    }
    .search-item:last-child {
      margin-right: 0px;
    }
    .search-item-label {
      display: inline-block;
      min-width: 90px;
    }
    .ant-calendar-picker {
      max-width: 294px;
      width: 100%;
    }
    .export-btn {
      position: absolute;
      right: 0px;
    }
  }
}
</style>
